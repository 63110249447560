// import httpConfig from '@/constant/http-config'
import axios from 'axios'
// import store from './store'
// axios.defaults.timeout = 1000000
// axios.defaults.baseURL = 'http://106.13.90.65:8090'
// 此处为axios配置请求头，
axios.interceptors.request.use(
  config => {
    // 此处做请求拦截，如果有需要
    // if (!(config.url.includes('login'))) {
    //   config.headers = {
    //     // 'token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpbk5hbWUiOiIxMTExMTExMTExMSIsImV4cCI6MTU5Mjg5OTEyMH0.5CaVkZXPK0pfJitgZu7N-n-L3RpHMFEXNNw12iPXexQ', // 安全处理每次请求带验证值token
    //     // "x-ajax": true,
    //     // "content-type":'application/json',
    //   }
    // }
    return config
  },
  err => {
    window.console.log(err)
    return Promise.reject(err)
  }
)
//响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    if ((response.status === 200 && response.request.status === 200) || (response.status === 201 && response.request.status === 201)) {//成功判断
      // console.log(response, 'opoppopopopop')
      // if (response.data) {
      //   return response.data
      // }
    }
    return response
  },
  error => {//失败判断
    // if (error.response) {
    // switch (error.response.status){
    //   case 401:
    //     if (!error.response.config.url.includes('login')) {
    //       store.dispatch('clearToken').then(() => location.reload())
    //     }
    //   }
    // }
    return Promise.reject(error)
  }
)


export default axios
