import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hasLogin:false,
    userid:'',
    defaultActive:'1-1-1',
    listVal:{},
    screenWidth: document.documentElement.clientWidth,
    screenHeight: document.documentElement.clientHeight,
    orCodeImg:'',
    orCodeTitle: '',
    sourceNameForm:'',
    serviceMenuList: '',
    productTypeList: ''
  },
  mutations: {
    Loginout(state){
      state.hasLogin = false
      state.userid = ''
    },
    changeActive(state,val){
      state.defaultActive = val
    },
    changeListVal(state,val){
      state.listVal = val
    },
    setWidth (state, value) {
      state.screenWidth = value
     },
    setHeight (state, value) {
    state.screenHeight = value
    },
    changeOrCodeImg(state, value){
      state.orCodeImg = value
    },
    changeOrCodeTitle(state, value){
      state.orCodeTitle = value
    },
    setSourceNameForm(state, value){
      state.sourceNameForm = value
    },
    changeServiceMenuList(state, value){
      state.serviceMenuList = value
    },
    changeProductTypeList(state, value){
      state.productTypeList = value
    }
  },
  actions: {
  },
  modules: {
  }
})
