import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import 'vant/lib/index.css';
import api from '@/api/index.js'
import MetaInfo from 'vue-meta-info'
import Fragment from 'vue-fragment'
// import  VConsole  from  'vconsole';
// import moment from 'moment'
import SlideVerify from 'vue-monoplasty-slide-verify';
import hevueImgPreview from 'hevue-img-preview'
import { Sticky } from 'vant';
import vuetify from '@/plugins/vuetify'
import VueLazyload from 'vue-lazyload'
import '@/font/font.css';
Vue.use(Sticky);
Vue.use(hevueImgPreview)
Vue.use(SlideVerify)
Vue.use(ElementUI)
Vue.use(ViewUI)
Vue.use(MetaInfo)
Vue.use(Fragment.Plugin)
import Vant from 'vant';
Vue.use(Vant);
// moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
// Vue.prototype.$moment = moment;//赋值使用
Vue.use(VueLazyload,{
    preLoad: 1,
    loading: require('./assets/solution/preloading.png'),
    attempt: 1,
})
Vue.config.productionTip = false
Vue.prototype.$api = api
//Vue.prototype.$path = "http://new.xaunqd.com"
// Vue.prototype.$path = "http://10.2.2.68:8090"
// Vue.prototype.$path = "http://pre.xaunqd.com"
Vue.prototype.$path = ''
// Vue.prototype.$path = "http://www.xaunqd.com"
 // let vConsole = new VConsole();
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mounted() {
        // let goUrl = this.isMobile();
        // if (goUrl === 1) {
        //    //移动端地址
        //   window.location.href = "http://m.unqd.chnqc315.cn/";
        // }
        document.dispatchEvent(new Event('render-event'));
    },
    // methods:{
    //     isMobile(){
    //         let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //         // localStorage.setItem('isiphone',flag)
    //         localStorage.setItem('ismobile',flag?1:0);
    //         let goUrl = flag ? 1 : 0;
    //         return goUrl;
    //     }
    // }
}).$mount('#app')
