// const baseUrlContentRoot = 'http://admin.unqd.chnqc315.cn' //服务器地址
    //const baseUrlContentRoot = 'http://10.2.2.91:8090'

    // const baseUrlContentRoot = 'http://10.18.4.190:8090'
   //const baseUrlContentRoot = "http://10.2.2.91:8090"
//const baseUrlContentRoot = 'http://10.2.2.68:8090'
  // const baseUrlContentRoot = 'http://pre.xaunqd.com'
  // const baseUrlContentRoot = 'http://api.xaunqd.com'
const baseUrlContentRoot = 'https://api.xaunqd.com'


function getUrl(url) {
    return `${baseUrlContentRoot}${url}`
}

export default getUrl
